import { Tooltip } from "@mui/material";

// Define the type for a client
interface Client {
	slug: string;
	title: string;
	website: string;
}

interface ClientLogoProps {
	client: Client;
}

export default function ClientLogo({ client }: ClientLogoProps) {
	const path = `${process.env.PUBLIC_URL}/images/clients/${client.slug}.svg`;

	return (
		<Tooltip placement="top" arrow title={client.title}>
			<a href={client.website} rel="noreferrer" target="_blank">
				<img src={path} alt={client.title} title={client.title} />
			</a>
		</Tooltip>
	);
}
