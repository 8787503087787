import classNames from "classnames";
import React, { useEffect, useState } from "react";

export default function OnLoadAnimator({
	order,
	content,
}: {
	order: number;
	content: React.ReactNode;
}) {
	const [loadState, setLoadState] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoadState(true);
		}, 125 * order);

		// Clear the timeout if the component unmounts
		return () => clearTimeout(timer);
	}, [order]);

	return (
		<div
			className={classNames("animate-me", {
				"animate-in": loadState,
			})}
		>
			{content}
		</div>
	);
}
