import { useCallback } from "react";

import OnLoadAnimator from "../components/OnLoadAnimator";
import ClientLogo from "../utilities/ClientLogo";

// Define the type for a client
interface Client {
	id: string; // Adjust the fields based on your actual client structure
	name: string;
	logoUrl: string;
	slug: string;
	title: string;
	website: string;
}

interface ClientRollProps {
	clients: Client[];
}

export default function ClientRoll({ clients }: ClientRollProps) {
	const shuffle = useCallback((clients: Client[]): Client[] => {
		const shuffled = clients
			.map((client) => ({ client, sort: Math.random() }))
			.sort((a, b) => a.sort - b.sort)
			.map(({ client }) => client);
		return shuffled;
	}, []);

	return (
		<div className="clients-grid">
			{shuffle(clients).map((client, index) => (
				<OnLoadAnimator
					key={client.id} // Use a unique identifier for the key
					order={index}
					content={<ClientLogo key={client.id} client={client} />}
				/>
			))}
		</div>
	);
}
