import ReactDOMServer from "react-dom/server";

import EmailSignatureTemplate from "../components/EmailSignatureTemplate";
import DATA from "../data.json";

export default function EmailSignature() {
	const staff = DATA["staff"];

	return Object.values(staff).map((staffer, index) => (
		<div key={index}>
			<EmailSignatureTemplate
				personName={staffer.name}
				personEmail={staffer.email}
				personTitle={staffer.title}
				personPhone={staffer.phone}
				personAvatar={staffer.avatar}
			/>
			<textarea
				contentEditable
				value={ReactDOMServer.renderToString(
					<EmailSignatureTemplate
						personName={staffer.name}
						personEmail={staffer.email}
						personTitle={staffer.title}
						personPhone={staffer.phone}
						personAvatar={staffer.avatar}
					/>
				)}
			/>
		</div>
	));
}
